export enum FeatureFlags {
  NEWS_COMMENTS = 'news_comments',
  NEWS_FEEDBACK = 'news_feedback',
  NEWS_PRINT = 'news_print',
  NOTIFICATIONS_DIGESTMAIL = 'notifications_digestmail',
  ONBOARDING_PRIVACYPOLICY = 'onboarding_privacypolicy',
  PROFILE_FLAGINFO = 'profile_flaginfo',
  PROFILE_PICTURE = 'profile_picture',
  PROFILE_HIDE_EMAIL = 'profile_hide_email',
  PROFILE_HIDE_BIRTHDAY = 'profile_hide_birthday',
  PROFILE_HIDE_CONTRACTTYPE = 'profile_hide_contracttype',
  PROFILE_HIDE_DIVISION = 'profile_hide_division',
  PROFILE_HIDE_SUBDIVISION = 'profile_hide_subdivision',
  PROFILE_HIDE_COUNTRY = 'profile_hide_country',
  PROFILE_HIDE_PREFERRED_LANGUAGE = 'profile_hide_preferred_language',
  SEARCH_PEOPLE = 'search_people',
  SEARCH_SITES = 'search_sites',
  CHAT_WEB = 'chat_web',
  CHAT_MOBILE = 'chat_mobile',
  DOCUMENTS_MODULE = 'module_documentlibrary',
  RANDOM_FEATURED_NEWS = 'random_featured_news',
}
